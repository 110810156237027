import '../css/index.scss'
import 'swiper/swiper.scss'
import Swiper from 'swiper/swiper-bundle'

// banner轮播图
new Swiper(".banner-container .swiper-container", {
    loop: true,
    pagination: {
        el: '.banner-container .swiper-container .swiper-pagination',
        clickable: true,
        bulletActiveClass: 'cur'
    },
    autoplay: true
});


new Swiper(".yewu .yewu-container .yewu-wrapper .swiper-container", {
    loop: true,
    pagination: {
        el: '.yewu .page-box',
        clickable: true,
        bulletActiveClass: 'cur',
        renderBullet: function(index, className) {
            var text = '';
            switch(index) {
                case 0:
                    text = '挽回婚姻';
                    break;
                case 1:
                    text = '挽回爱情';
                    break;
                case 2:
                    text = '分离小三';
                    break;

            }
            var html = '<span class="page-item ' + className + '">' + text + '</span>';
            return html;
        }
    },
    autoplay: true
});

new Swiper('.tutor .swiper-container', {
    slidesPerView:1.55,
    spaceBetween: '3.5%',
    loop: true,
    pagination: {
        el: '.tutor .swiper-pagination',
        clickable: true,
        bulletActiveClass: 'cur'
    },
    navigation: {
        nextEl: '.tutor .control-btn .big-right-btn',
        prevEl: '.tutor .control-btn .big-left-btn'
    },
    lazy: true
});
    
new Swiper(".tutor-group .swiper-container", {
    loop: true,
    pagination: {
        el: '.tutor-group .swiper-container .swiper-pagination',
        clickable: true,
        bulletActiveClass: 'cur'
    },
    navigation: {
        nextEl: '.tutor-group .control .small-right-btn',
        prevEl: '.tutor-group .control .small-left-btn'
    },
    autoplay: true
});


new Swiper(".media-newspaper .newspaper-container .newspaper-list", {
    loop: true,
    direction: 'vertical',
    spaceBetween: 30,
    pagination: {
        el: '.media-newspaper .newspaper-container .media-logo',
        clickable: true,
        bulletActiveClass: 'cur',
        renderBullet: function(index, className) {
            var html = '<div class="' + className + '"><span></span></div>';
            return html;
        }
    }
});

var screenWidth = window.screen.width > 750 ? 750 : window.screen.width;
new Swiper(".customer-feedback .swiper-container", {
    loop: true,
    speed: 200,
    slidesPerView: 'auto',
    // slidesPerView: 3,
    loopedSlides: 5,
    // loopAdditionalSlides: 1,
    resistanceRatio: 1,
    spaceBetween: - (screenWidth * 0.21),
    watchSlidesProgress: true,
    on: {
        progress: function(progress) {
            for (let i = 0; i < this.slides.length; i++) {
                var slide = this.slides.eq(i);
                var slideProgress = this.slides[i].progress;
                
                var scale = 1;
                var opacity = 1;
                var zindex = 100;
                if (slideProgress > 0.6) {
                    opacity = 0.7;
                }
                if (slideProgress != 0) {
                    var sp = Math.abs(slideProgress);
                    scale = sp - sp * 0.12 - sp + 1;
                    zindex = zindex - i;
                    if (slideProgress > 0.95) {
                        opacity = 0;
                    } else if (slideProgress < -2.9) {
                        opacity = 0;
                    }
                }

                slide[0].style.transform = 'scale3d(' + scale + ',' + scale + ',1.5)';
                slide[0].style.opacity = opacity;
                slide[0].style.zIndex = zindex;
            }
		}
    },
    pagination: {
        el: '.customer-feedback .swiper-pagination',
        clickable: true,
        bulletActiveClass: 'cur'
    },
    navigation: {
        nextEl: '.customer-feedback .big-right-btn',
        prevEl: '.customer-feedback .big-left-btn'
    }
});


var affectionDataPagination = document.querySelector('.affection-data .pagination');
if (affectionDataPagination) {
    affectionDataPagination = affectionDataPagination.cloneNode(true).children;
    new Swiper('.affection-data .swiper-container', {
        loop: true,
        pagination: {
            el: '.affection-data .pagination',
            bulletClass: 'bullet',
            bulletActiveClass: 'cur',
            clickable: true,
            renderBullet: function(i, n) {
                return '<span class="'+n+'">'+affectionDataPagination[i].innerText+'</span>';
            }
        }
    });
}

// 列表页
new Swiper('.category-nav .swiper-container', {
    slidesPerView: 3,
    navigation: {
        nextEl: '.category-nav .control-btn'
    },
    on: {
        init: function(s) {
            var slide = null;
            for (let i = 0; i < this.slides.length; i++) {
                slide = this.slides[i];
                if (slide.classList.contains('cur')) {
                    this.slideTo(i);
                    break;
                }
            }
        }
    }
});